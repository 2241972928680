
import AuthMixin from "@/mixins/AuthMixin";
import { EventBus } from "@/services/event-bus";
import { AuthModule } from "@/store/auth";
import { Profile } from "@planetadeleste/vue-mc-shopaholic";
import { Component, Mixins } from "vue-property-decorator";

@Component
export default class Account extends Mixins(AuthMixin) {
  obModel: Profile = new Profile();

  mounted(): void {
    this.$nextTick(() => {
      if (!AuthModule.isLogged) {
        this.$router.replace({ name: "login" });
        return;
      }

      this.obModel = AuthModule.user.clone();
    });

    EventBus.on("logged-out", () => {
      this.$router.push({ name: "login" });
    });
  }

  beforeDestroy(): void {
    EventBus.off("logged-out");
  }

  get fullName(): string {
    const arNameList = [this.user.name];
    if (this.user.last_name) {
      arNameList.push(this.user.last_name);
    }

    return arNameList.join(" ");
  }
}
